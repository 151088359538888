<template>
  <div class="bg-main training vh-100" style="background: #1d1d1d">
    <VuePullRefresh
      :key="pullKey"
      :on-refresh="appRefresh"
      :config="{ startLabel: '', readyLabel: '', loadingLabel: '', pullDownHeight: 60 }"
    >
      <v-container class="py-0 px-0 pb-10" fluid>
        <v-alert
          v-if="!$online"
          dense
          tile
          color="gray"
          icon="mdi-alert-circle-outline"
          class="alert-offline text-headline"
        >
          {{ $t("t_no_connection") }}
        </v-alert>
        <v-card flat class="" tile>
          <v-card-text class="d-flex pb-0">
            <p class="headline mb-0">Training</p>
            <v-spacer></v-spacer>
            <v-btn
              v-if="$platform == 'web'"
              icon
              class="ml-2"
              :class="{ rotate: this.$store.state.loader.loading }"
              @click="refresh"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </v-card-text>
          <v-card-text class="d-flex pb-0"
            ><v-btn small class="mx-2" @click="showAsset('pdf')">PDF</v-btn
            ><v-btn small class="mx-2" @click="showAsset('video')">Video</v-btn></v-card-text
          >
          <div class="py-3 mx-4">
            <vue-pdf-embed v-if="asset == 'pdf'" ref="pdf" :source="pdfSource" :scale="1" />
            <video v-if="asset == 'video'" ref="videoPlayer" :src="videoSrc" controls autoplay="true"></video>
          </div>
        </v-card>
        <v-divider></v-divider>
        <v-card flat class="ma-4" tile>
          <div class="py-3">
            <v-pagination v-model="examSlide" :length="9" color="#3fb401" total-visible="7" dark></v-pagination>
          </div>
        </v-card>
        <v-divider></v-divider>

        <v-card flat class="ma-4" tile>
          <div class="py-3">
            <v-carousel v-model="examSlide" hide-delimiters>
              <v-carousel-item v-for="(q, i) in questions" :key="q.id">
                <v-sheet :color="q.color" height="100%" tile>
                  <div class="question-header pt-4 text-h6" style="text-align: center">Example Quiz</div>
                  <div class="px-6 pt-6">
                    <div>{{ i + 1 }}. {{ q.question }}</div>
                    <div class="pt-4">
                      <v-radio-group v-model="q.answer">
                        <v-radio v-for="(a, j) in q.answers" :key="j" :label="a.answer" :value="a.response"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </v-sheet>

                <!-- <v-sheet :color="q.color" height="100%" tile>
                  <v-row class="fill-height" align="center" justify="center">
                    <div>{{ q.question }}</div>
                     <v-col cols="12" class="question">{{ q.question }}</v-col>
                    <v-col cols="12" class="answer">
                      <v-radio-group v-model="q.answer">
                        <v-radio v-for="(a, j) in q.answers" :key="j" :label="a.answer" :value="a.response"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-sheet> -->
              </v-carousel-item>
            </v-carousel>
            <v-pagination
              v-model="examSlide"
              :length="9"
              color="#3fb401"
              total-visible="7"
              dark
              class="paginate"
            ></v-pagination>
          </div>
        </v-card>
      </v-container>
    </VuePullRefresh>
  </div>
</template>

<script>
import Helpers from "@/mixins/helpers";
import VuePullRefresh from "vue-pull-refresh";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "Training",
  mixins: [Helpers],
  components: { VuePullRefresh, VuePdfEmbed },
  computed: {},
  watch: {},
  data: () => ({
    pullKey: 0,
    pushes: null,
    pdfSource: "/OSHA3859.pdf",
    asset: "",
    videoSrc: "http://vjs.zencdn.net/v/oceans.mp4",
    page: 5,
    examSlide: 0,
    colors: ["primary darken-4", "secondary darken-4", "yellow darken-4", "red darken-4", "orange"],
    questions: [
      {
        id: 1,
        color: "red darken-4",
        question: "foo bar 1?",
        answers: [
          { answer: "1", response: null },
          { answer: "2", response: null },
          { answer: "3", response: null },
        ],
      },
      {
        id: 2,
        color: "yellow darken-4",
        question: "foo bar 2?",
        answers: [
          { answer: "1a", response: null },
          { answer: "2a", response: null },
          { answer: "3a", response: null },
        ],
      },
      {
        id: 3,
        color: "blue darken-4",
        question: "foo bar 3?",
        answers: [
          { answer: "1b", response: null },
          { answer: "2bb", response: null },
          { answer: "3bbb", response: null },
        ],
      },
      {
        id: 4,
        color: "green darken-4",
        question: "foo bar 4?",
        answers: [
          { answer: "1c", response: null },
          { answer: "2cc", response: null },
          { answer: "3ccc", response: null },
        ],
      },
      {
        id: 5,
        color: "red darken-4",
        question: "foo bar 1?",
        answers: [
          { answer: "1", response: null },
          { answer: "2", response: null },
          { answer: "3", response: null },
        ],
      },
      {
        id: 6,
        color: "yellow darken-4",
        question: "foo bar 2?",
        answers: [
          { answer: "1a", response: null },
          { answer: "2a", response: null },
          { answer: "3a", response: null },
        ],
      },
      {
        id: 7,
        color: "blue darken-4",
        question: "foo bar 3?",
        answers: [
          { answer: "1b", response: null },
          { answer: "2bb", response: null },
          { answer: "3bbb", response: null },
        ],
      },
      {
        id: 8,
        color: "green darken-4",
        question: "foo bar 4?",
        answers: [
          { answer: "1c", response: null },
          { answer: "2cc", response: null },
          { answer: "3ccc", response: null },
        ],
      },
      {
        id: 9,
        color: "green darken-4",
        question: "foo bar 4?",
        answers: [
          { answer: "1c", response: null },
          { answer: "2cc", response: null },
          { answer: "3ccc", response: null },
        ],
      },
    ],
  }),

  methods: {
    refresh() {},
    appRefresh() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          this.pullKey += 1;
        }, 1000);
      });
    },
    showAsset(type) {
      this.asset = type;
      if (type == "video") {
        this.$refs.videoPlayer.play();
      }
    },
  },
};
</script>
<style lang="scss">
.v-window__prev,
.v-window__next {
  top: 15px;
  z-index: 999;
}
.paginate {
  position: relative;
  top: -57px;
  background: rgba(0, 0, 0, 0.5);
  padding: 7px;
}
</style>
